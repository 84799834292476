<template>
  <div class="exmaHeaderPage float">
    <div class="step-view">
      <h4>分数：{{ score }}/{{ totalScore }}</h4>
      <p>计时：{{ exmaAt }}</p>
    </div>
    <div class="controller-view right">
      <button class="stop left" @click="atStop"><i class="bg"></i>暂停</button>

      <a-popconfirm
        title="请再次确认是否返回首页"
        ok-text="是"
        cancel-text="否"
        @confirm="goHome"
      >
        <button class="home left"><i class="bg"></i>首页</button>
      </a-popconfirm>
      <a-switch :checked="layoutStyle" @click="layoutChange" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      checked: true,
    };
  },
  methods: {
    ...mapActions({
      setLayout: "theme/setLayoutStyle",
      stopHandleTime: "answer/stopHandleTime",
    }),
    goHome() {
      this.$router.replace("/");
    },
    atStop() {
      this.stopHandleTime();
    },
    layoutChange() {
      this.setLayout("layoutStyle");
    },
  },
  computed: {
    ...mapGetters(["layoutStyle", "exmaAt", "totalScore", "score"]),
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>